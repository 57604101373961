export const VIDEO_BASE = {
  controls: false,
  loop: true,
  muted: true,
  autoplay: true,
  preload: 'auto',
}

export const AUTOPLAY = {
  autoPlay: true,
  muted: true,
}

export const PLAYINLINE = {
  playsInline: true,
  'webkit-playsinline': 'true',
}

export const VIDEO_JS_BASE = {
  fill: false,
  fluid: false,
  responsive: true,
  playbackRates: [0.5, 1, 1.25, 1.5, 2, 4, 8],
  html5: {
    hls: {
      overrideNative: true,
      bandwidth: 30000000,
      limitRenditionByPlayerDimensions: false,
    },
    nativeAudioTracks: true,
    nativeVideoTracks: true,
    nativeTextTracks: true,
  },
  ...VIDEO_BASE,
}

// Html5.Events = [
//   'loadstart',
//   'suspend',
//   'abort',
//   'error',
//   'emptied',
//   'stalled',
//   'loadedmetadata',
//   'loadeddata',
//   'canplay',
//   'canplaythrough',
//   'playing',
//   'waiting',
//   'seeking',
//   'seeked',
//   'ended',
//   'durationchange',
//   'timeupdate',
//   'progress',
//   'play',
//   'pause',
//   'ratechange',
//   'resize',
//   'volumechange'
// ]
