import { Flex } from 'theme-ui'

const Box = ({ children, sx, column, clear, fit = false, ...props }) => {
  return (
    <Flex
      sx={{ variant: 'box', gridColumn: column, ...sx }}
      data-clear={clear == null ? null : clear}
      data-fit={fit}
      {...props}
    >
      {children}
    </Flex>
  )
}

export default Box
