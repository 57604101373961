import { useState, useRef, useCallback } from 'react'
import { useUpdateEffect, useConditionalEffect } from '@react-hookz/web'
import { omit, merge } from 'lodash'

import videojs from 'video.js'

import { VIDEO_JS_BASE, AUTOPLAY, PLAYINLINE } from './__consts'

const VideoJS = (
  _sources = [],
  { options = {}, ratio = '16/9', fit = 'cover', fade = true, classNames = '', onReady, ...props },
  predicates = [true]
) => {
  const player = useRef(null)
  const videoRef = useRef(null)
  const wrapperRef = useRef(null)

  const sources = _sources.filter(Boolean)
  const valid = !!sources.length

  const [ready, setReady] = useState(false)

  const watch = { sources, options, ratio, fit, onReady }
  const changedKey = JSON.stringify(watch)

  const handleReady = (_player) => {
    _player.on('canplay', () => !!!ready && setReady(true))
    onReady && onReady(_player)
  }

  const pip = options?.pip ?? true

  const configs = merge(VIDEO_JS_BASE, {
    ...omit(options, ['pip', 'playinline']),
    ...(options?.autoplay ? AUTOPLAY : {}),
    controlBar: { liveDisplay: !!!pip, pictureInPictureToggle: !!pip },
    sources,
  })

  useConditionalEffect(
    () => {
      const init = videojs(videoRef.current, configs, () => handleReady(player.current))
      player.current = init

      return () => {
        setReady(false)
        player.current.dispose()
      }
    },
    [changedKey, ...predicates],
    [valid, ...predicates]
  )

  useUpdateEffect(() => {
    wrapperRef.current.setAttribute('data-ready', !!ready)
  }, [ready])

  const { attributes, styles } = {
    attributes: {
      ...(options?.playinline ? PLAYINLINE : {}),
      disablePictureInPicture: !!!pip,
      ...props,
    },
    styles: {
      overflow: 'hidden',
      '--aspect-ratio': ratio,
      video: { objectFit: fit },

      ...(!!fade && {
        '[data-vjs-player]': { transition: 'opacity var(--ui-duration) var(--ui-ease)' },
      }),
    },
  }

  const Video = useCallback(
    ({ children, sx, ...props }) => (
      <div
        key={changedKey}
        ref={wrapperRef}
        sx={{ variant: 'videojs', ...styles, ...sx }}
        data-ready={false}
        data-videojs
        {...props}
      >
        <div data-vjs-player>
          <video ref={videoRef} className={`video-js ${classNames}`} {...attributes}>
            {children}
          </video>
        </div>
      </div>
    ),
    [changedKey]
  )

  return [
    Video,
    {
      valid,
      ready,
      player: player.current,
      handlePlay: () => handlePlay(player.current),
    },
  ]
}

function handlePlay(node) {
  const playing = isPlaying(node)
  if (!!!playing) node.play()
}

function isPlaying(node) {
  return !!(node.currentTime > 0 && !node.paused && !node.ended && node.readyState > 2)
}

export default VideoJS
