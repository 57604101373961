import { useState, useEffect, useContext } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useConditionalEffect, useUpdateEffect } from '@react-hookz/web'
import { useTween } from '@monobits/gsap'

import { useVideo } from '../components/Video'
import Box from '../components/Box'
import Underline from '../components/Underline'

import { AppContext } from '../layouts/index'

const _m3u8 = 'https://player.vimeo.com/external/695924343.m3u8?s=9aac76600be3aef3c17f13d382865501704bf9d9'
const _mp4 =
  'https://player.vimeo.com/progressive_redirect/playback/695924343/rendition/1080p?loc=external&signature=d2f75ece005bff7ca8f4e61cdee4921160147f9354d33085e4f1c30fd9f731c5'

const src = ({ m3u8, mp4 }) => [
  !!m3u8 && { src: m3u8, type: 'application/x-mpegURL' },
  !!mp4 && { src: mp4, type: 'video/mp4' },
]

const Home = () => {
  const [open, setOpen] = useState(false)
  const [sources, setSources] = useState([])
  const { breakpoint } = useContext(AppContext)

  useEffect(() => {
    // setSources(src({ m3u8: _m3u8, mp4: _mp4 }))

    // if native quality is bad
    setSources(src(supportsHLS() ? { mp4: _mp4 } : { m3u8: _m3u8 }))
  }, [])

  const [Video, { valid, handlePlay }] = useVideo(sources, {
    fit: 'cover',
    fade: true,
    options: {
      autoplay: true,
      playsinline: true,
      controls: false,
      loop: true,
      pip: false,
    },
  })

  const video = !!valid && <Video sx={{ variant: 'main.video' }} />

  const view = ['mobile', 'tablet', 'desktop'][breakpoint]
  const cta =
    view === 'mobile'
      ? {
          as: 'button',
          onClick: () => setOpen((prev) => !prev),
        }
      : {}

  const [tween, element] = useTween(({ gsap }) => {
    return gsap
      .timeline({ paused: true })
      .add('start')
      .to(
        '[data-gsap="content"] [data-area="middle"],[data-gsap="content"] [data-area="footer"]',
        {
          autoAlpha: 0,
          duration: 0.4,
          ease: 'power1.out',
        },
        'start'
      )
      .to('html', { '--blur': '8px', '--opacity': 0.4, duration: 0.6, ease: 'power1.out' }, 'start')
      .add('ready')
      .to('[data-gsap="menu"]', {
        autoAlpha: 1,
        duration: 0.4,
        ease: 'power1.out',
      })
  })

  useConditionalEffect(
    () => {
      tween?.[!!open ? 'play' : 'reverse']()
    },
    [open],
    [tween != null]
  )

  useConditionalEffect(
    () => {
      setOpen(false)
    },
    [breakpoint],
    [breakpoint != null],
    undefined,
    useUpdateEffect
  )

  return (
    <>
      <GatsbySeo title="$STYL" openGraph={{ title: '$STYL | Styllar Token™' }} />

      <div
        data-gap="overlay"
        sx={{
          pointerEvents: 'none',
          opacity: 'var(--opacity, 0)',
          bg: 'background',
          position: 'fixed',
          inset: 0,
          zIndex: 2,
        }}
      />

      {video}

      <section
        ref={element.ref}
        sx={{ variant: 'main.container', visibility: 'hidden', opacity: 0 }}
        data-gsap="menu"
        aria-hidden={true}
      >
        <div data-area="header" sx={{ visibility: 'hidden' }}>
          <Box column="1/-1" />
        </div>
        <div data-area="middle" />
        <div data-area="footer">
          <div sx={{ display: 'flex', gridColumn: '1/-1' }}>
            <Box sx={{ width: 'fit-content' }}>
              <Underline variant="caption" inverted>
                Styllar™
              </Underline>
            </Box>

            <Box sx={{ flexGrow: 1 }} clear="left">
              <Underline variant="caption" inverted>
                ©{new Date().getFullYear()} all rights reserved
              </Underline>
            </Box>
          </div>
        </div>
      </section>

      <section data-gsap="content" sx={{ variant: 'main.container' }}>
        <div data-area="header">
          <Box {...cta} column={['1/-1', 'span 2', 'span 1']} sx={{ justifyContent: 'space-between' }}>
            <Underline variant="caption" inverted>
              $STYL™
            </Underline>

            <div sx={{ variant: 'main.burger' }} data-active={!!open} />
          </Box>

          {view !== 'mobile' && (
            <>
              <Box column={[null, 'span 4', 'span 3']} clear="left" sx={{ width: ['100%', null, 'fit-content'] }}>
                <div sx={{ '>*': { width: 'fit-content' } }}>
                  <Underline variant="caption" inverted>
                    Styllar token™
                  </Underline>
                  <Underline variant="caption" inverted>
                    building a sustainable digital universe
                  </Underline>
                </div>
              </Box>

              <Box
                column={[null, '1/-1', '5/7']}
                sx={{
                  justifySelf: 'end',
                  width: ['100%', null, 'fit-content'],
                  pt: ['var(--pb)', null, 'var(--pt)'],
                }}
              >
                <Underline variant="caption" inverted>
                  More info coming soon
                </Underline>
              </Box>
            </>
          )}
        </div>

        <div data-area="middle" onClick={handlePlay} />

        <div data-area="footer">
          {view !== 'mobile' && (
            <>
              <Box column="span 2" sx={{ width: 'fit-content' }}>
                <Underline variant="caption" inverted>
                  Styllar™
                </Underline>
              </Box>

              <Box column="4/7" sx={{ justifySelf: 'end', width: 'fit-content' }}>
                <Underline variant="caption" inverted>
                  ©{new Date().getFullYear()} all rights reserved
                </Underline>
              </Box>
            </>
          )}

          {view === 'mobile' && (
            <Box column="1/-1" sx={{ width: '100%' }}>
              <div sx={{ '>*': { width: 'fit-content' } }}>
                <Underline variant="caption" inverted>
                  Styllar token™
                </Underline>
                <Underline variant="caption" inverted>
                  building a sustainable digital universe
                </Underline>
              </div>
            </Box>
          )}
        </div>
      </section>
    </>
  )
}

function supportsHLS() {
  var video = document.createElement('video')
  return Boolean(video.canPlayType('application/vnd.apple.mpegURL') || video.canPlayType('audio/mpegurl'))
}

export default Home
